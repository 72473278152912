.flims-laax-logo {
  width: 100%;
  height: 100px;
}

span.companyName {
  font-size: 11px;
  color: #000;
  display: block;
  text-decoration: none;
}

.flims-laax-logo-link {
  text-decoration: none;
  cursor: pointer;
}
