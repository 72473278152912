abbr {
  text-decoration: none;
}

.react-calendar {
  color: grey;
  width: 315px;
  max-width: 100%;
  font-family: Roboto, sans-serif;
  line-height: 1.125em;
  background-color: #fff;
  padding-top: 10px;
  font-size: 20px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.react-calendar__navigation__label__labelText {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 18px;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 22px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #fff;
}
.react-calendar__navigation button[disabled] {
  color: #000;
}
.react-calendar__month-view__days__day {
  font-size: 14px;
  font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
  color: #808080;
}

.react-calendar__month-view__weekdays {
  color: #666;
  text-align: left;
  font-weight: 100;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  font-weight: 900;
  max-width: 100%;
  padding: 0.75em 0.5em;
  background: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #fff;
}
.react-calendar__tile--now {
  background: rgba(356, 64, 42, 0.1);
}
.react-calendar__tile--hasActive {
  background: #fff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #fff;
}
.react-calendar__tile--active {
  background: #fff;
  color: #808080;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background: #fff;
}

.react-calendar__tile--range {
  background: #fff;
}

.booking-calendar-arrow-icon {
  max-width: 1em;
}

.react-calendar__tile{
  padding-right: 20px;
  padding-top: 6px;
  height: 44px;
}
.react-calendar__tile--now {
  background: #fff;
  color: #808080;
}
